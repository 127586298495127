import { Amplify } from "aws-amplify";
import {
  confirmResetPassword as confirmResetPasswordAmplify,
  confirmSignUp,
  fetchUserAttributes,
  resendSignUpCode,
  resetPassword as resetPasswordAmplify,
  signIn as signInAmplify,
  signInWithRedirect,
  signOut as signOutAmplify,
} from "aws-amplify/auth";
import { useAuthStore } from "stores/auth";
import { LogInInput } from "types";
import { onLogout } from "./tracking";

const STAGE = import.meta.env.VITE_APP_STAGE;

export const isNotLocal = (stage: string) => {
  if (
    stage === "develop" ||
    stage === "staging" ||
    stage === "preprod" ||
    stage === "production"
  ) {
    return true;
  }

  return false;
};

export const LOCAL_OR_STAGE = isNotLocal(STAGE) ? STAGE : "develop";

const bucketName = `${LOCAL_OR_STAGE}-public-static-assets-bucket`;

export const ASSETS_S3_BASE_URL = `https://inside-${bucketName}.s3.eu-central-1.amazonaws.com/connecteed-inops`;

export const HOME_URL = window.location.protocol + "//" + window.location.host;

const CLIENT_METADATA = {
  useCommon: "true",
  useSso: "false",
  HOME_URL,
  logoUrl: `${ASSETS_S3_BASE_URL}/inops.png`,
  clientId: import.meta.env.VITE_APP_COGNITO_CLIENT_ID,
  companyName: "Inops.fr",
};

export const initialize = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: import.meta.env.VITE_APP_COGNITO_POOL_ID,
        userPoolClientId: import.meta.env.VITE_APP_COGNITO_CLIENT_ID,
        loginWith: {
          oauth: {
            domain: import.meta.env.VITE_APP_COGNITO_DOMAIN,
            scopes: ["email", "openid", "aws.cognito.signin.user.admin"],
            redirectSignIn: [`${HOME_URL}/`],
            redirectSignOut: [`${HOME_URL}/login`],
            responseType: "code",
          },
        },
      },
    },
  });

  // cognitoUserPoolsTokenProvider.setKeyValueStorage(
  //   new CookieStorage({
  //     domain: isNotLocal(STAGE) ? "freelance.com" : "localhost",
  //     expires: 10,
  //   }),
  // );
};

export const signIn = async ({ email, password }: LogInInput) => {
  // sign the user with cognito
  await signInAmplify({ username: email, password });
};

export const signOut = async () => {
  await signOutAmplify();

  localStorage.clear();
  // clean trackers
  onLogout();
  useAuthStore.setState({ isLoggedIn: false });
};

export const verifyEmail = async (email: string, code: string) => {
  return confirmSignUp({
    username: email,
    confirmationCode: code,
    options: {
      clientMetadata: { useCommon: "true" },
    },
  });
};

export const resendCode = async (username: string) => {
  return resendSignUpCode({
    username,
    options: {
      clientMetadata: {
        ...CLIENT_METADATA,
        actionUrl: `${HOME_URL}/verify-email`,
      },
    },
  });
};

export const resetPassword = async (email: string) => {
  return resetPasswordAmplify({
    username: email,
    options: {
      clientMetadata: {
        ...CLIENT_METADATA,
        actionUrl: `${HOME_URL}/confirm-reset-password`,
      },
    },
  });
};

export const confirmResetPassword = async (
  email: string,
  code: string,
  password: string,
) =>
  confirmResetPasswordAmplify({
    username: email,
    confirmationCode: code,
    newPassword: password,
  });

export const loginWithAzure = async (providerName: string) => {
  try {
    await fetchUserAttributes();
  } catch (error) {
    await signInWithRedirect({
      provider: {
        custom: providerName,
      },
    });
  }
};
