import {
  DocumentToCheckStatusEnum,
  GetProviderOrderQuery,
  type Bill,
  type GetPartnerInvoiceByNameQuery,
} from "graphql/__generated__/graphql";
import { keyBy } from "lodash-es";
import { Dictionary } from "modules/role/RoleProjectsTable/RoleProjectsModal";

export const shouldGetExistingToast = (
  invoice: GetPartnerInvoiceByNameQuery["invoice"],
  providerOrderId: string,
  bill?: Bill | null,
) => {
  if (invoice) {
    if (invoice.name === bill?.name) {
      if (bill?.status === DocumentToCheckStatusEnum.Rejected) {
        if (invoice.providerOrders.map((i) => i.id).includes(providerOrderId)) {
          return false;
        }
        return true;
      }
      return false;
    }
    return true;
  }

  return false;
};

export const shouldDisplayErrorMessage = ({
  costByPhaseTotalFinal,
  billableCost,
  orderAmountHT,
  costByPhase,
  phases,
  isForfait,
  originalRepartitionByPhaseId,
}: {
  costByPhaseTotalFinal: number;
  billableCost: number;
  orderAmountHT: number;
  costByPhase: {
    phaseId: string;
    cost: number;
    time: number;
  }[];
  phases: GetProviderOrderQuery["order"]["phases"];
  isForfait: boolean;
  originalRepartitionByPhaseId: Dictionary<{
    __typename?: "Repartition" | undefined;
    phaseId: string;
    cost: number;
    time: number;
  }>;
}): { hasError: boolean; message: string } => {
  if (costByPhaseTotalFinal <= 0) {
    return {
      hasError: true,
      message: "Le montant total de la facture doit être supérieur à zéro.",
    };
  }

  if (costByPhaseTotalFinal > billableCost) {
    return {
      hasError: true,
      message:
        "Le montant de la facture dépasse le montant facturable. Veuillez vérifier et ajuster le montant de la facture.",
    };
  }

  if (costByPhaseTotalFinal - orderAmountHT > 1) {
    return {
      hasError: true,
      message:
        "Le montant de la facture saisie dépasse le montant total de la commande. Veuillez vérifier et ajuster le montant de la facture.",
    };
  }

  const phasesById = keyBy(phases, (p) => p.id);

  for (const { phaseId, cost, time } of costByPhase) {
    const phase = phasesById[phaseId];

    if (phase) {
      const costBillable =
        phase.costBillable + (originalRepartitionByPhaseId[phaseId]?.cost || 0);

      if (isForfait) {
        if (cost > costBillable) {
          return {
            hasError: true,
            message: `Le coût pour la phase "${phase.name}" ne peut pas dépasser le montant facturable.`,
          };
        }
      } else {
        const isRoundNumber = time % 1 === 0;
        const isHalfNumber = time % 1 === 0.5;

        if (!isRoundNumber && !isHalfNumber) {
          return {
            hasError: true,
            message: `Le temps pour la phase "${phase.name}" doit être un nombre entier ou un demi-jour.`,
          };
        }
      }
    }
  }

  return { hasError: false, message: "" };
};
